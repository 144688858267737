import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import moment from 'moment';
import React, { useEffect, FC } from 'react';
import styled from 'styled-components';
import App from '../components/App';
import Page from '../components/Page';
import { Menu } from '../components/Menu';
import { Titles } from '../components/Titles';
import { ReactCusdis } from 'react-cusdis';

export default function Template({ data }) {
  const { post } = data;
  const { slug, title, date, parent } = post;
  const { body, timeToRead } = parent;

  return (
    <App>
      <Page title={title}>
        <Menu></Menu>
        <Titles>
          <h1>{title}</h1>
          {date && <h3>{date}</h3>}
        </Titles>
        <i>About a {timeToRead}-minute read</i>
        <MDXRenderer>{body}</MDXRenderer>
        <h2>Comments</h2>
        <ReactCusdis
          attrs={{
            host: 'https://travisgeis-cusdis.vercel.app',
            appId: 'bfc86e4c-f4e1-4135-8790-cf52804a5850',
            pageId: `${slug}/`,
            pageTitle: title,
            pageUrl: `https://travisgeis.com/${slug}`,
          }}
        />
      </Page>
    </App>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    post(id: { eq: $id }) {
      slug
      title
      date(formatString: "D MMMM YYYY")
      parent {
        ... on Mdx {
          body
          timeToRead
        }
      }
    }
  }
`;
